<template>
  <div class="list_panel">
    <div class="list_content">
      <div :title="item.name" class="list_item" v-for="(item,idx) in listShow" :key="idx" @click="goDetailFn(item)">
        <div class="item_date">
          {{formatDate(item.publishDate)}}
        </div>
        <div class="item_pic">
          <img :src="item.imageUrl">
        </div>
        <div class="item_desc">
          <div class="item_desc_topic">
            {{item.title}}
          </div>
          <div class="item_desc_value">
            {{item.summary}}
          </div>
        </div>
      </div>
    </div>
    <div class="pagination_content">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="ktInfos.length"
        :page-size.sync="pageSize"
        :current-page.sync="pageCurrent">
      </el-pagination>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  name:"compList",
  components: {
  },
  // 定义属性
  data() {
    return {
      ktInfos:[],
      pageCurrent:1,
      pageSize:10
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    listShow(){
      return this.ktInfos.filter((item,idx)=>{
        return idx>=(this.pageCurrent-1)*this.pageSize&&idx<this.pageCurrent*this.pageSize;
      })
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 获取图片
    getPic(name){
      return require(`../../images/${name}`)
    },
    goDetailFn(info){
      this.$emit("goDetail",info)
    },
    async getNewList () {
      const { data } = await this.$http.get(`${window.SEVERURL}/safeglp/generator/news/list?currPage=1&limit=99999`)
      this.ktInfos = data.page.list
      
      this.totalPage = data.page.totalPage
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份是从0开始的，加1并确保两位数字
      const day = date.getDate().toString().padStart(2, '0'); // 确保天是两位数字
      return `${year}.${month}.${day}`; // 使用点来连接
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getNewList()
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
  .list_panel{
    width: 100%;
    .list_content{
      .list_item{
        height: 275px;
        border-bottom: 1px solid #D7D7D7;
        display: flex;
        padding-top: 60px;
        box-sizing: border-box;
        .item_date{
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          font-weight: bold;
          font-size: 24px;
          color: #000000;
          line-height: 48px;
          margin-right: 80px;
        }
        .item_pic{
          height: 176px;
          width: 300px;
          margin-right: 45px;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .item_desc{
          flex: 1;
          overflow: hidden;
          .item_desc_topic{
            font-family: Microsoft YaHei UI, Microsoft YaHei UI;
            font-weight: bold;
            font-size: 24px;
            color: #353B60;
            line-height: 48px;
            margin-bottom: 12px;
          }
          .item_desc_value{
            font-family: Microsoft YaHei UI, Microsoft YaHei UI;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            line-height: 24px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

    }
    .pagination_content{
      display: flex;
      justify-content: center;
    }
  }
</style>