<template>
  <div class="detail_panel">
    <div class="table_header" :title="detailInfo.title">
      {{detailInfo.title}}
    </div>
    <div class="article_content">
      <!-- <div class="pic_content">
        <img :src="detailInfo.imageUrl" alt="" srcset="">
      </div> -->
      <!-- <div class="date_content">
        {{detailInfo.publishDate}}
      </div> -->
      <div class="article_main" v-html="detailInfo.content">
      </div>
    </div>
    <div class="back_btn">
      <div class="btn_el" @click="$emit('goBack')">返回列表</div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  name:"compDetail",
  props:{
    detailInfo:{
      type:Object,
      default:()=>{
        return {
          name:"未知",
          details:[]
        }
      }
    }
  },
  components: {
  },
  // 定义属性
  data() {
    return {
      
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 获取图片
    getPic(name){
      return require(`../images/${name}`)
    }
    
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
  .detail_panel{
    width: 100%;
    .table_header{
      width: 100%;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 32px;
      color: #000000;
      text-align: center;
      padding: 0 10px;
      box-sizing: border-box;
      margin-bottom: 1rem;
    }
    .article_content{
      .pic_content{
        width: 100%;
        display: flex;
       justify-content: center;
        img{
          // width: 600px;
          // height: 400px;
          object-fit: cover;
        }
      }
      .date_content{
        font-family: Microsoft YaHei UI, Microsoft YaHei UI;
        font-weight: bold;
        font-size: 20px;
        color: #CCCCCC;
        line-height: 35px;
        display: flex;
        justify-content: flex-end;
      }
      .article_main{
        font-family: Microsoft YaHei UI, Microsoft YaHei UI;
        font-weight: 400;
        font-size: 18px;
        color: #666666;
        line-height: 24px;
        white-space: pre-wrap;
      }
    }
    .back_btn{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 18px;
      color: #0086D1;
      line-height: 48px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      .btn_el{
        cursor: pointer;
      }
    }
  }
</style>